div.filterRoot {
    color: #fff;
    width: 200px;
    height: 20px;
    background: rgba(13, 13, 13, 0.7);
    border: 1px solid #ffffff33;
    font-size: 12px;
    float: right;
    border-radius: 2px;
  }

  div.filterSelect {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 5px;
  }

  svg.filterIcon {
    color: #fff;
  }

  .filterMenuCheckboxChecked {
    color: rgba(255, 255, 26, 1) !important;
  }

  li.filterMenuItem {
    font-size: 12px;
    padding: 5px 0;
    color: #fff;
  }

  .filterMenuItemSelected {
    color: rgba(255, 255, 26, 1);
  }

  span.filterMenuCheckbox  {
    padding: 0 10px ;
    color: #fff ;
  }
